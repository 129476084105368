//:::::::::::::::::::::::::
import {
  HttpClient,
  GetBookingsByBO,
  ModelModal,
  BookingResponseTypeBO,
  AvailableSlotResponseBO,
  Helper,
  Types,
  moment,
  makeObservable,
  action,
  OverAllBookingBO,
  Path,
  ModelClinic,
  HttpStatus,
  BookingInformationResponseBO,
} from 'src/_Shared/global'
import accountStore, { AccountUtils } from '../stores/account'
import bookingStore from '../stores/booking'
import insuranceStore from '../stores/insurance'
//:::::::::::::::::::::::::

class ModelBooking {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelBooking
  //Properties
  appointment: string | undefined
  booker: Types.Patient | undefined
  bookingId: string | undefined //For cancellation
  primaryCare: boolean = false
  urgentCare: boolean = false
  //Virtual care
  virtualCare: boolean = false

  //Time slots for the BookTime tabs
  selectedTab: string = 'Today' //Today, Tomorrow, More Dates
  timeSlotPackages: Types.TimeSlotPackage[] = []
  // Pull the time slots for the selected day (today, tomorrow, or a calendar item)
  selectedTimeSlotPackage: Types.TimeSlotPackage | undefined
  selectTimeSlotPackage(day: number) {
    return this.timeSlotPackages.find((timeSlotPackage) => {
      return timeSlotPackage.day === day
    })
  }
  //Date min and max for date picker
  earliestAppointmentDate: Date = new Date()
  latestAppointmentDate: Date = new Date()
  //Patient info
  selectedPatient: string = ''
  patientGuarantor: string = ''
  isNextCareEmployee: boolean = false
  nextCareEmployeeName: string | undefined
  //Consent forms
  consentFormIds: string[] = []
  //Cancellation
  cancellationReasons: string[] = [
    'Scheduled by mistake',
    'Went to different clinic',
    'Feeling better',
    'Need to reschedule',
    'Went to my primary care doctor',
    'Other',
  ]

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Reactive Properties
      appointment: true,
      booker: true,
      primaryCare: true,
      urgentCare: true,
      virtualCare: true,
      selectedTab: true,
      selectedTimeSlotPackage: true,
      timeSlotPackages: true,
      earliestAppointmentDate: true,
      latestAppointmentDate: true,
      selectedPatient: true,
      patientGuarantor: true,
      isNextCareEmployee: true,
      nextCareEmployeeName: true,

      //Methods
      setAppointment: action,
      setBooker: action,
      setPrimaryCare: action,
      setUrgentCare: action,
      setVirtualCare: action,
      setSelectedTab: action,
      setSelectedTimeSlotPackage: action,
      setTimeSlotPackages: action,
      setEarliestAppointmentDate: action,
      setLatestAppointmentDate: action,
      setSelectedPatient: action,
      setPatientGuarantor: action,
      setIsNextCareEmployee: action,
      setNextCareEmployeeName: action,
      setConsentFormIds: action,
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Getters
  //==============================
  getAppointment(): string | undefined {
    //***
    return localStorage.getItem('appointment') ?? undefined
  }
  getBooker(): Types.Patient | undefined {
    //***
    if (localStorage.getItem('booker')) {
      return JSON.parse(localStorage.getItem('booker')!)
    } else {
      return undefined
    }
  }
  getBookingSummary(): Types.BookingSummary | undefined {
    //***
    if (localStorage.getItem('bookingSummary')) {
      return JSON.parse(localStorage.getItem('bookingSummary')!)
    } else {
      return undefined
    }
  }

  //==============================
  //#region Setters
  //==============================
  setBooker(value: Types.Patient) {
    this.booker = value
    //***
    localStorage.setItem('booker', JSON.stringify(value))
  }
  setAppointment(value?: Date) {
    //Value must be in slot format: 2024-04-28T16:30:00-07:00
    var ready = ''

    if (value) {
      ready = Helper.convertDateObjectToSlotFormat(value)
    }

    this.appointment = ready
    //***
    localStorage.setItem('appointment', ready)
  }
  setBookingSummary(value: Types.BookingSummary) {
    //***
    localStorage.setItem('bookingSummary', JSON.stringify(value))
  }
  setBookingId(value: string) {
    this.bookingId = value
    localStorage.setItem('bookingId', value)
  }
  setPrimaryCare(value: boolean) {
    this.primaryCare = value
  }
  setUrgentCare(value: boolean) {
    this.urgentCare = value
  }
  setVirtualCare(value: boolean) {
    this.virtualCare = value
  }
  setSelectedTab(value: string) {
    this.selectedTab = value
  }
  setSelectedTimeSlotPackage(value?: Types.TimeSlotPackage) {
    this.selectedTimeSlotPackage = value
  }
  setTimeSlotPackages(value: Types.TimeSlotPackage[]) {
    this.timeSlotPackages = value
  }
  setEarliestAppointmentDate(value: Date) {
    this.earliestAppointmentDate = value
  }
  setLatestAppointmentDate(value: Date) {
    this.latestAppointmentDate = value
  }
  setConsentFormIds(value: string[]) {
    this.consentFormIds = value
  }

  //==============================
  //#region Patient Info
  //==============================
  setSelectedPatient(value: string) {
    this.selectedPatient = value
  }
  setPatientGuarantor(value: string) {
    this.patientGuarantor = value
  }
  setIsNextCareEmployee(value: boolean) {
    this.isNextCareEmployee = value
  }
  setNextCareEmployeeName(value?: string) {
    this.nextCareEmployeeName = value
  }

  //==============================
  //#region Find Booking
  //==============================
  async find(phone: string, clinicId: string) {
    ModelModal.showLoader('Please Wait', 'Finding appointment...')
    console.log(`🔄 Finding booking...`)

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<GetBookingsByBO[], any>()
      const response = await httpClient.post('v2/solv/allbookings', null, {
        headers: {
          phonenumber: phone,
        },
      })

      //Find all the matches for this clinic
      var matches = response.data?.filter((item: GetBookingsByBO) => {
        return item?.data?.location_details?.id === clinicId
      })

      //Make sure the appointment is for today
      matches = matches?.filter((item: GetBookingsByBO) => {
        return moment(item.data.booking_details?.appointment_date).isSame(
          moment(),
          'day',
        )
      })

      //Remove any canceled appointments
      matches = matches?.filter((item: GetBookingsByBO) => {
        return item.data.booking_details?.status !== 'cancelled'
      })

      console.log('🦤 Bookings Found:')
      console.log(matches)

      if (matches && matches.length > 0) {
        //Send back the first one found
        return matches[0].data.booking_details
      } else {
        return undefined
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('findBooking', error)
      return undefined
    }
  }

  //==============================
  //#region Find All Bookings
  //==============================
  async findAll(phone: string) {
    var bookings: Types.BookingSummary[] = []

    console.log(`🔄 Finding all bookings...`)

    try {
      //Lookup booking with phone number

      const httpClient = new HttpClient<GetBookingsByBO[], any>()
      const response = await httpClient.post('v2/solv/allbookings', null, {
        headers: {
          phonenumber: phone,
        },
      })

      if (response.data) {
        //Convert to booking summary type
        for (const booking of response.data) {
          if (booking.data.booking_details && booking.data.location_details) {
            //Build a new booking summary
            var summary: Types.BookingSummary = {
              firstName: booking.data.booking_details.first_name,
              lastName: booking.data.booking_details.last_name,
              appointment: booking.data.booking_details.appointment_date,
              bookingId: booking.data.booking_details.id,
              clinicName: booking.data.location_details.name,
              clinicId: booking.data.location_details.id,
              email: booking.data.booking_details.email,
              isWalkIn: booking.data.booking_details.is_walk_in,
            }

            // filter out cancelled bookings
            if (booking.data.booking_details?.status !== 'cancelled') {
              bookings.push(summary)
            }
          }
        }
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('Find All Bookings', error)
    }

    //:::
    return bookings
  }

  //==============================
  //#region Create Booking
  //==============================
  async create() {
    const bookingState = bookingStore.getState()
    const isOnDemand = bookingState.isOnDemand
    const clinic = JSON.parse(
      localStorage.getItem('clinic') || '{}',
    ) as Types.Clinic

    let appointment: string = localStorage.getItem('appointment')!
    if (isOnDemand) {
      // Get updated slots just in case
      const slots = await this.getTimeSlotsForClinic(clinic.id)
      if (slots) {
        appointment = (
          slots.timeSlots.length
            ? moment(slots.timeSlots[0].date).local().format()
            : localStorage.getItem('appointment')
        )!
      }
    }

    //Divert to updating a booking if they already have a booking ID
    const bookingId = localStorage.getItem('bookingId')
    if (bookingId) {
      //:::
      await this.update(bookingId, appointment)

      insuranceStore.getState().setBookingId(bookingId)
      return bookingId
    }
    const { account, createDependent, fetchAccount } = accountStore.getState()

    //Proceed with booking creation...
    ModelModal.showLoader('Please Wait', 'Submitting...')

    //Pull all the needed data from the cache
    const booker = JSON.parse(
      localStorage.getItem('booker') || '{}',
    ) as Types.Patient
    const patient = JSON.parse(
      localStorage.getItem('patient') || '{}',
    ) as Types.Patient
    const practiceDetails = ModelClinic.getSelectedVirtual()?.practiceDetails

    const employee = localStorage.getItem('employee') || ''
    const reason = localStorage.getItem('reason') || ''

    //Get the consent form IDs for the clinic
    await ModelClinic.getReviewAndSignFormsForClinic(
      this.virtualCare && practiceDetails ? practiceDetails.id : clinic.id,
    )
    const consent = JSON.parse(
      localStorage.getItem('consent') || '{}',
    ) as string[]

    //Save new dependent, if applicable
    if (localStorage.getItem('newDependent')) {
      const dependent = JSON.parse(
        localStorage.getItem('newDependent')!,
      ) as Types.Patient
      ModelModal.showLoader('Please Wait', 'Creating dependent...')
      await createDependent({
        firstName: dependent.firstName,
        lastName: dependent.lastName,
        dateOfBirth: dependent.dateOfBirth,
        email: dependent.email,
        phoneNumber: dependent.phone,
        // NOTE: Taking these from the account holder because they are needed
        address1: account?.address1,
        address2: account?.address2,
        raceId: AccountUtils.getRaceIdByName('Undisclosed'), // We are sending "Unknown" elsewhere but the ID is the same as "Undisclosed"
        ethnicityId: AccountUtils.getEthnicityIdByName('Undisclosed'), // We are sending "Unknown" elsewhere but the ID is the same as "Undisclosed"

        city: account?.city ?? '',
        countryId: account?.countryId ?? '',
        sex: 'O', // NOTE: must have a sex here. This is a placeholder. We need to add it to the form
        state: account?.state ?? '',
        zipCode: account?.zipCode ?? '',
      })
      ModelModal.showLoader('Please Wait', 'Syncing account ...')
      await fetchAccount()
      ModelModal.hide()
    }

    console.info('🔄 Creating booking...', appointment)

    const booking: OverAllBookingBO = {
      //::::::::::
      //Booking - patient details go in the booking
      bookingBO: {
        ...(isOnDemand ? { is_walk_in: true } : {}),
        appointment_date: appointment, //Time offset required; don't format
        birth_date: Helper.convertDateOfBirthSimpleToAPI(patient.dateOfBirth),
        email: patient.email,
        first_name: patient.firstName,
        last_name: patient.lastName,
        location_id:
          this.virtualCare && practiceDetails ? practiceDetails.id : clinic.id,
        phone: Helper.convertPhoneToAPI(patient.phone),
        reason: reason,
      },
      //::::::::::
      //Paperwork - booker's details go into guardian
      paperworkBO: {
        responses: {
          patientFirstName: patient.firstName,
          patientLastName: patient.lastName,
          birthDate: Helper.convertDateOfBirthToAPI(patient.dateOfBirth),
          patientAddressCity: '',
          patientAddressStreet: '',
          patientBirthSex: patient.sex
            ? Helper.convertBirthSexToAPI(patient.sex)
            : '',
          email: patient.email,
          patientAddressStreetTwo: '',
          primaryPhone: Helper.convertPhoneToAPI(patient.phone),
          patientAddressState: '',
          selfPayCheck: '',
          patientAddressZip: '',
          race: 'Unknown',
          ethnicity: 'Unknown',
          'AreyouandeligibledependentofaNextCareemployeelegalspouseorlegalchildundertheageof26IfYESpleasealsoprovidethenameoftheNextCareemployee_54970840-f5fc-11eb-8664-dd02b14340ab':
            employee,
          guardianBirthDate: Helper.convertDateOfBirthToAPI(booker.dateOfBirth),
          guardianFirstName: booker.firstName,
          guardianEmail: booker.email,
          guardianPhone: booker.phone,
          guardianLastName: booker.lastName,
          guardianCity: '',
          guardianAddressTwo: '',
          guardianZip: '',
          guardianState: '',
          guardianAddress: '',
        },
        status: 'in_progress',
      },
      consentBO: {
        location_consent_form_ids: consent,
        signer_signature: '',
      },
    }

    //If a booking ID is set, update the booking
    if (localStorage.getItem('bookingId')) {
      console.log('🔄 Updating booking...')

      try {
        //Lookup booking with phone number
        const httpClient = new HttpClient<any, any>()
        const response = await httpClient.post(
          'v2/solv/bookings/update',
          booking,
          {
            headers: {
              bookingid: localStorage.getItem('bookingId')!,
            },
          },
        )

        console.log(response)
      } catch (error) {
        //--- ! ---
        Helper.handleError('Update Booking', error)
      }
      return
    }

    //Create a booking
    console.log('🔄 Creating booking...')
    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<BookingResponseTypeBO, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/schedulevisit',
        booking,
      )

      //Check for conflicting appointments
      console.log(response.status)
      if (response.status === HttpStatus.Conflict) {
        const booking = bookingState.bookings.find((booking) => {
          return (
            moment(booking.appointment).isSame(moment(appointment), 'minute') &&
            booking.clinicId === clinic.id
          )
        })
        if (booking) {
          // If I have an appointment at that date/time, then it is mine
          ModelModal.showError(
            'Conflict',
            'You already have an appointment booked.',
          )
        } else {
          // If I don't have an appointment at that date/time, then it is someone else's
          ModelModal.showAppointmentTaken()
        }
        return undefined
      }

      if (response.data && response.data.Bookingid) {
        this.setBookingId(response.data.Bookingid)
        insuranceStore.getState().setBookingId(response.data.Bookingid)

        console.log('✅ Booked')

        return response.data.Bookingid
      } else {
        console.log(response)
        console.log('Booking error')
        return undefined
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('createBooking', error)
    }
  }

  //==============================
  //#region Cancel Booking
  //==============================
  async cancelBooking(bookingId: string, reason: string) {
    ModelModal.showLoader('Please Wait', 'Canceling...')
    console.log('🔄 Canceling booking...')

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      await httpClient.post(
        'v2/solv/bookings/cancel',
        { cancellation_reason: reason },
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )

      //Done
      ModelModal.showSuccess(
        'Success',
        'Your appointment was successfully canceled.',
        'Okay',
        Path.Home,
      )
    } catch (error) {
      //--- ! ---
      Helper.handleError('cancelBooking', error)
    }
  }

  //==============================
  //#region Update Booking
  //==============================
  async update(bookingId: string, appointment: string) {
    ModelModal.showLoader('Please Wait', 'Updating...')

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/reschedule',
        { appointment_date: appointment },
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )
      console.log('Booking update response:', response)
      //:::
    } catch (error) {
      //--- ! ---
      Helper.handleError('createBooking', error)
    }
    return bookingId
  }

  //==============================
  //#region Confirm Booking
  //==============================
  async confirm(bookingId: string) {
    ModelModal.showLoader('Please Wait', 'Checking in...')

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/update',
        { status: 'confirmed' },
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )
      console.log('Booking confirm response:', response)
      //:::
    } catch (error) {
      //--- ! ---
      Helper.handleError('createBooking', error)
    }
    ModelModal.showSuccess('Confirmed', 'Your booking is confirmed.')
  }

  //==============================
  //#region Get Booking Details
  //==============================
  async getBookingDetails(bookingId: string) {
    console.log('🔄 Getting booking details...')

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<BookingInformationResponseBO, any>()
      const response = await httpClient.post('v2/solv/bookings', null, {
        headers: {
          bookingid: bookingId,
        },
      })

      //:::
      if (response.data?.data) {
        return response.data!.data
      } else {
        throw Error(`Get booking details error: ${response}`)
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('createBooking', error)
    }
  }

  //==============================
  //#region Clear Cache
  //==============================
  clear() {
    //***
    //Clear saved items
    localStorage.removeItem('clinic')
    localStorage.removeItem('clinicIsOpen')
    localStorage.removeItem('clinicOpenHours')
    localStorage.removeItem('appointment')
    localStorage.removeItem('booker')
    localStorage.removeItem('patient')
    localStorage.removeItem('consent')
    localStorage.removeItem('reason')
    localStorage.removeItem('employee')
    localStorage.removeItem('visitType')
    //Clear booking summary
    localStorage.removeItem('bookingSummary')
    localStorage.removeItem('bookingId')
    localStorage.removeItem('checkInTime')
    // booking store
    bookingStore.getState().setIsOnDemand(false)
  }

  //==============================
  //#region Get Time Slots
  //==============================
  async getTimeSlotsForClinic(clinicId: string) {
    const httpClient = new HttpClient<AvailableSlotResponseBO, any>()
    const bookingState = bookingStore.getState()
    const isOnDemand = bookingState.isOnDemand

    const response = await httpClient.post('v2/solv/locations/slots', '', {
      headers: {
        locationid: clinicId,
        ondemand: isOnDemand ? 1 : 0,
      },
    })

    if (
      response?.data?.data &&
      response?.data?.data?.length > 0 &&
      response?.data?.data[0].slots.length > 0
    ) {
      let clinicTimes: Types.ClinicTimes = {
        nextAvailable: '',
        earliestAvailable: new Date(),
        latestAvailable: new Date(),
        timeSlots: [],
      }

      const slots = response?.data?.data[0].slots

      const daysOfAppointmentsAllowed = 7;

      // Filter out slots that are not available, unless it's an on demand booking
      //(but no more than 7 per the business requirement)
      const slotCutoff = moment().add(daysOfAppointmentsAllowed, 'days');
      
      const availableSlotObjects = slots.filter((slot) => {
        var isWithin7Days = moment(slot.appointment_date) <= slotCutoff ;
          //console.log(`${moment(slot.appointment_date)} <= ${slotCutoff} ? ${isWithin7Days}`);
          
          return isWithin7Days && (isOnDemand || slot.availability === 1)
        })
      
      //Get only the date
      const availableTimes = availableSlotObjects.map(
        (slot) => slot.appointment_date,
      )

      var timeSlotPackages: Types.TimeSlotPackage[] = []

      //Get unique list of days
      var days: number[] = availableTimes.map((slot: string) =>
        moment(slot).toDate().getDate(),
      )

      //Create a unique set of days 
      
      let uniqueDays = [...new Set(days)].slice(0, daysOfAppointmentsAllowed)

      //Go through each day and get the associated slots (this kind of work should be done in the API layer, but whatevs)
      for (const day of uniqueDays) {
        const foundSlots = availableTimes.filter((slot: string) => {
          //Convert the timeslot to a day and see if it matches then return it
          return moment(slot).toDate().getDate() === day
        })
        //Put all the slots we found for this day in our master array
        timeSlotPackages.push({
          day: day,
          displayDate: moment(foundSlots[0]).format('dddd, MMMM Do'),
          date: moment(foundSlots[0]).toDate(),
          slots: foundSlots,
        })
      }

      clinicTimes.timeSlots = timeSlotPackages

      //Set earliest available appointment for date picker min
      var earliestAvailable = new Date()
      if (timeSlotPackages.length > 0 && timeSlotPackages[0].slots.length > 0) {
        const firstSlot = timeSlotPackages[0].slots[0]
        earliestAvailable = moment(firstSlot).toDate()
        clinicTimes.earliestAvailable = earliestAvailable

        //Set today's slots
        //might not necessarily be firstSlot
        this.setSelectedTimeSlotPackage(
          timeSlotPackages.find((p) => p.day === new Date().getDate()),
        )
      }

      //Set latest available appointment for date picker max
      if (timeSlotPackages.length > 0) {
        var latestDay = timeSlotPackages.pop()
        if (latestDay === undefined) return
        const lastSlot = latestDay.slots.pop()
        clinicTimes.latestAvailable = moment(lastSlot).toDate()
      }

      //Set next available for map details
      clinicTimes.nextAvailable =
        Helper.convertDateToDayAndTime(earliestAvailable)

      //:::
      return clinicTimes
    }
  }

  //==============================
  //#region Get All Next Available Time Slots (new experiemental API endpoint; not currently used)
  //==============================
  async getNextAvailableTimeSlots() {
    console.log(`🔄 Finding next available slots...`)

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()

      const response = await httpClient.get(
        'v2/solv/locations/nextavailableslots',
      )

      console.log('🐝')
      console.log(response)
    } catch (error) {
      //--- ! ---
      Helper.handleError('getNextAvailableTimeSlots', error)
    }
  }

  //==============================
  //#Cancel Booking
  //==============================

  //==============================
  //#region Misc
  //==============================
  getPatientName(patient: Types.Patient) {
    return `${patient.firstName} ${patient.lastName}`
  }
}

export default ModelBooking.Instance

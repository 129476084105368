//:::::::::::::::::::::::::
import { moment, ModelApp, Types } from 'src/_Shared/global'
import forge from 'node-forge'
import { sexOptions } from './stores/account/constants'
import { keyBy } from 'lodash'
//:::::::::::::::::::::::::

export namespace Helper {
  //==============================
  //#region Error Handling
  //==============================
  export async function handleError(from: string, error: any) {
    console.log('------------------------------------------------------')
    console.log(`⛔️ ${from} ⛔️`)
    console.log(error)
    console.log('------------------------------------------------------')
  }

  //==============================
  //#region Form Validation
  //==============================
  export enum InputValidator {
    Email = '[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}' +
      '\\@' +
      '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}' +
      '(' +
      '\\.' +
      '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}' +
      ')+',
    Alphabet = '^[a-zA-Z\\s]+$',
    PhoneWithoutDashes = '^\\d{3}\\d{3}\\d{4}$',
    PhoneWithDashes = '^\\d{3}-\\d{3}-\\d{4}$',
  }

  export function validate(validator: InputValidator, value: string) {
    const match = new RegExp(validator)
    return match.test(value)
  }

  export function validatePhone(value: string) {
    const dashesMatch = new RegExp(InputValidator.PhoneWithDashes)
    const withoutDashesMatch = new RegExp(InputValidator.PhoneWithoutDashes)
    return dashesMatch.test(value) || withoutDashesMatch.test(value)
  }

  export function validateSex(sex: string) {
    return sexOptions.includes(sex as Types.Sex)
  }

  export function isNotEmpty(value?: string) {
    if (value) {
      return value.length > 0
    } else {
      return false
    }
  }

  //Date validations
  export function isAtLeast18YearsOld(date: string) {
    const dateOfBirth = new Date(date)

    const date18YearsAgo = new Date()
    date18YearsAgo.setFullYear(date18YearsAgo.getFullYear() - 18)

    //:::
    return dateOfBirth < date18YearsAgo
  }

  export function isAValidBirthDate(date: string) {
    // must be in mm/dd/yyyy format
    const month = parseInt(date.split('/')?.[0])
    const day = parseInt(date.split('/')?.[1])
    const year = parseInt(date.split('/')?.[2])
    if (!month || !day || !year) return false
    if (month > 12 || year < 1900) return false

    // number of days in each month jan-dec
    let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29
    const monthIndex = month - 1
    if (day <= 0 || day > monthLength[monthIndex]) return false

    //:::
    return new Date(date) < new Date()
  }

  export function convertDateOfBirthFromAPI(dob: string) {
    if (!dob) return ''
    return moment(dob.split('T')[0]).format('MM/DD/YYYY')
  }
  export function convertDateOfBirthSimpleFromAPI(dob: string) {
    //YYYY-MM-DD to MM/DD/YYYY
    return moment(dob).format('MM/DD/YYYY')
  }
  export function convertDateOfBirthToAPI(dob: string) {
    //The middleware is picky about this format
    return `${moment(dob).format('YYYY-MM-DD')}T00:00:00`
  }
  export function convertDateOfBirthSimpleToAPI(dob: string) {
    return moment(dob).format('YYYY-MM-DD')
  }

  //==============================
  //#region Birth
  //==============================
  export function convertBirthSexFromAPI(birthSex: string) {
    switch (birthSex) {
      case 'M':
        return 'Male'
      case 'F':
        return 'Female'
      default:
        return 'Other'
    }
  }
  export function convertBirthSexToAPI(birthSex: string) {
    switch (birthSex) {
      case 'Male':
        return 'M'
      case 'Female':
        return 'F'
      default:
        return 'O'
    }
  }

  //==============================
  //#region Phone
  //==============================
  export function convertPhoneFromAPI(phone: string) {
    //Remove country code and just return last 10 digits
    if (phone) {
      return phone.substring(phone.length - 10)
    } else {
      return phone
    }
  }

  export function convertPhoneToAPI(phone: string) {
    //Remove dashes and add a +1
    var updatedPhone = phone.replaceAll('-', '')
    //Remove country code and just return last 10 digits
    return `+1${updatedPhone}`
  }

  //==============================
  //#region Dates
  //==============================
  //Used for birth date format
  export function convertDate(date: string) {
    return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') + 'T00:00:00'
  }
  //Example: 9:30am Tomorrow
  export function convertDateToDayAndTime(date: Date) {
    //Get the day
    const day = getDayFromDate(date, true)

    //Get the time
    const time = moment(date).format('h:mma')

    //:::
    return `${time} ${day}`
  }

  //Example: Today, February 17th at 9:15 am
  export function convertDateToDayAndTimeFull(date: Date) {
    console.log(date)

    //Get the day
    const day = getDayFromDate(date, false)

    //Get day of week
    var dayFull = moment(date).format('MMMM Do')

    //Get the time
    const time = moment(date).format('h:mm a')

    //:::
    return `${day}, ${dayFull} at ${time}`
  }

  function getDayFromDate(date: Date, hideToday: boolean) {
    //Get the day
    var day = moment(date).format('MMM D')

    //Check if it's today (new requirement: leave blank if today)
    var isToday = moment(date).isSame(new Date(), 'day')
    if (isToday) {
      day = 'Today'
    }
    if (hideToday) {
      day = ''
    }

    //Check if it's tomorrow
    const tomorrow = moment().add(1, 'day')
    var isTomorrow = moment(date).isBetween(
      moment(tomorrow).startOf('day'),
      moment(tomorrow).endOf('day'),
    )
    if (isTomorrow) {
      day = 'Tomorrow'
    }

    if (!isToday && !isTomorrow)
      day = 'More Dates'
    //:::

    return day
  }

  export function formatTime(timeFull: string) {
    //Converts 23:59:00, etc. to 11:59pm
    //Remove seconds :00
    var time = timeFull.slice(0, -3)

    //Break into hours and minutes
    const pieces = time.split(':')
    if (pieces.length === 2) {
      var hours = Number(pieces[0])
      var minutes = pieces[1]
      var suffix = hours >= 12 ? 'pm' : 'am'
      //A little math magic to handle 24-hour time (got it from Stack Overflow)
      hours = ((hours + 11) % 12) + 1
      //:::
      return `${hours}:${minutes}${suffix}`
    } else {
      return 'NA'
    }
  }
  export function convertAppointmentDate(date: string) {
    //Converts 2024-04-25T15:30:00-07:00 to Wednesday, April 25th 2024 at 3:30pm
    return moment(date).format('LLLL')
  }

  export function convertAppointmentMonthDay(date: string) {
    return moment(date).format('MMMM Do')
  }

  export function convertAppointmentToday(date: string) {
    //Convert 2024-04-28T04:30:00+0000 to just time
    return moment(date).format('h:mma')
  }

  export function convertDateOfBirth(dob: string) {
    //From 2001-01-01 to MM/DD/YYYY
    return moment(dob).format('MM/DD/YYYY')
  }
  export function convertDateObjectToSlotFormat(date: Date) {
    //From Date to 2024-04-28T16:30:00-07:00 (the format required by the booking API)
    var _val = moment(date).format('YYYY-MM-DD[T]HH:mm:ssZ');

    //console.log("convertDateObjectToSlotFormat() received: ", date);
    //console.log("convertDateObjectToSlotFormat() returned: ", _val);
    
    
    return _val;
  }

  //Check if the current time is within 15 minutes of an appointment to enable check-in
  export function isAppointmentFifteenMinutesAway(appointment: string) {
    const appt = moment(appointment)

    const now = moment().toDate()
    const fifteenMinutesBefore = moment(appt.subtract(15, 'minutes')).toDate()
    //:::
    if (now >= fifteenMinutesBefore) {
      return true
    } else {
      return false
    }
  }

  //==============================
  //#region Misc
  //==============================
  export function abbreviateName(firstName: string, lastName: string) {
    return `${firstName} ${lastName.charAt(0)}.`
  }
  export function isScrolledIntoView(element: HTMLDivElement) {
    var rect = element.getBoundingClientRect()
    var elementTop = rect.top
    var elementBottom = rect.bottom

    // Partially visible elements return true:
    const isVisible = elementTop < window.innerHeight && elementBottom >= 0
    return isVisible
  }

  //==============================
  //#region Cryptography
  //==============================
  export async function encryptCardImages(cardFront: string, cardBack: string) {
    const publicKeyResponse = await ModelApp.getPublicKey(
      'v2/app/get/publicKey',
    )

    const symmetricKeys = generateSymmetricKeyAndIv()

    const encryptedFrontImage = symmetricMessageEncryption(
      symmetricKeys[0],
      symmetricKeys[1],
      cardFront,
    )

    const encryptedBackImage = symmetricMessageEncryption(
      symmetricKeys[0],
      symmetricKeys[1],
      cardBack,
    )

    const encryptedSymmetricKeys = asymmetricMessageEncryption(
      publicKeyResponse.data?.publicKey!,
      symmetricKeys[0],
      symmetricKeys[1],
    )

    const ready: Types.Encryption = {
      cardFront: encryptedFrontImage,
      cardBack: encryptedBackImage,
      publicKey: publicKeyResponse.data?.publicKey!,
      symmetricKey: encryptedSymmetricKeys[0],
      initializationVector: encryptedSymmetricKeys[1],
    }
    //:::
    return ready
  }
  export function encode64(message: string) {
    return forge.util.encode64(message)
  }

  export const decode64 = (base64: string) => {
    return forge.util.decode64(base64)
  }

  export function generateSymmetricKeyAndIv() {
    const symmetricKey = forge.random.getBytesSync(32)
    const iv = forge.random.getBytesSync(16)
    return [encode64(symmetricKey), encode64(iv)]
  }

  export function symmetricMessageDecryption(
    symmetricKeyBase64: string,
    ivBase64: string,
    messageBase64: string,
  ) {
    const keyBytes = decode64(symmetricKeyBase64)
    const ivBytes = decode64(ivBase64)
    const encryptedData = decode64(messageBase64)

    // Check key and IV length
    if (keyBytes.length !== 32) {
      throw new Error(
        `Key length is incorrect. Should be 32 bytes for AES-256. Was ${keyBytes.length} bytes.`,
      )
    }
    if (ivBytes.length !== 16) {
      throw new Error(
        `IV length is incorrect. Should be 16 bytes for AES-CBC. Was ${keyBytes.length} bytes.`,
      )
    }
    const decipher = forge.cipher.createDecipher('AES-CBC', keyBytes)
    decipher.start({ iv: ivBytes })
    decipher.update(forge.util.createBuffer(encryptedData))
    decipher.finish()
    return decipher.output.toString()
  }

  export function symmetricMessageEncryption(
    symmetricKeyBase64: string,
    ivBase64: string,
    messageBase64: string,
  ) {
    const cipher = forge.cipher.createCipher(
      'AES-CBC',
      decode64(symmetricKeyBase64),
    )
    cipher.start({ iv: decode64(ivBase64) })
    cipher.update(forge.util.createBuffer(messageBase64))
    cipher.finish()
    return encode64(cipher.output.getBytes())
  }

  export function asymmetricMessageEncryption(
    publicKeyBase64: string,
    message: string,
    iv: string,
  ) {
    const publicKey = forge.pki.publicKeyFromPem(decode64(publicKeyBase64))
    const cipheredMessageInBytes = publicKey.encrypt(message)
    const cipheredIvInBytes = publicKey.encrypt(iv)
    return [encode64(cipheredMessageInBytes), encode64(cipheredIvInBytes)]
  }

  export function decryptAes256Cbc(
    symmetricKeyBase64: string,
    ivBase64: string,
    encryptedMessageBase64: string,
  ) {
    const decipher = forge.cipher.createDecipher(
      'AES-CBC',
      decode64(symmetricKeyBase64),
    )
    decipher.start({ iv: decode64(ivBase64) })
    decipher.update(forge.util.createBuffer(decode64(encryptedMessageBase64)))
    const result = decipher.finish() // Check 'result' to see if decryption was successful

    if (result) {
      return decipher.output.toString() // Output decrypted text
    } else {
      throw new Error('Decryption failed. Check if the key and IV are correct.')
    }
  }

  // Function to decrypt the RSA encrypted symmetric key and IV with explicit padding set
  export function decryptRsa(
    encryptedDataBase64: string,
    privateKeyPem: string,
  ) {
    try {
      const privateKey = forge.pki.privateKeyFromPem(privateKeyPem)
      const encryptedData = forge.util.decode64(encryptedDataBase64)
      // Explicitly setting PKCS#1 v1.5 padding for compatibility
      const decryptedData = privateKey.decrypt(
        encryptedData,
        'RSAES-PKCS1-V1_5',
      )
      return decryptedData
    } catch (error) {
      console.error('RSA decryption error:', error)
      throw new Error('Failed to decrypt with RSA')
    }
  }
}
